import { roundNumber } from './converter';

// TODO: separate all calculations from formatting

export function calcMonthPriceWithoutFormatting(
    producerPrice /* EUR */,
    basePrice /* EUR per month */,
    consumption /* kWh/Jahr */
) {
    return (Number(consumption || 0) * Number(producerPrice || 0)) / 12 + Number(basePrice || 0);
}

export function calcMonthPrice(producerPrice /* EUR */, basePrice /* EUR per month */, consumption /* kWh/Jahr */) {
    return formatPrice(calcMonthPriceWithoutFormatting(producerPrice, basePrice, consumption)); // EUR per month
}

export function calcYearPrice(producerPrice /* EUR */, basePrice /* EUR per month */, consumption /* kWh/Jahr */) {
    return formatPrice(Number(consumption || 0) * Number(producerPrice || 0) + Number(basePrice || 0) * 12); // EUR per month
}

export function formatPrice(value = 0, locale = 'de', inCents = false) {
    // todo: detect language like here https://levelup.gitconnected.com/a-simple-next-js-frontend-for-a-multilingual-website-ae31a17387e2
    return new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
        value * (inCents ? 100 : 1)
    );
}

export function formatNumber(value = 0, locale = 'de') {
    return new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
}

export function calculateProducerSavings({
    usage,
    yearlySavings,
    monthlySavings,
    producer = {},
    extraBasePrice = 0,
    extraWorkingPrice = 0
} = {}) {
    const workingPrice = producer.deltaPrice + extraWorkingPrice;
    const deltaInEurPerYear = (Number(workingPrice || 0) * Number(usage || 0)) / 100;
    const deltaInEurPerMonth = deltaInEurPerYear / 12;

    const monthlyExtraBasePrice = Math.round((extraBasePrice / 12) * 100) / 100;

    const producerYearlySavings = (Number(yearlySavings || 0) - deltaInEurPerYear - extraBasePrice).toFixed(2);
    const producerMonthlySavings = (Number(monthlySavings || 0) - deltaInEurPerMonth - monthlyExtraBasePrice).toFixed(
        2
    );

    return {
        yearlySavings: Number(producerYearlySavings),
        monthlySavings: Number(producerMonthlySavings)
    };
}

export function calculatePricesWithExtra({
    monthlyBasePrice,
    extraBasePrice,
    producerPrice,
    extraWorkingPrice,
    usage,
    dayConsumption,
    nightConsumption,
    isTimeDependingConsumption,
    producerPriceNT
} = {}) {
    // extra base price is provided as an annual amount (eur / year), and extra working price is provided in cents (ie, 5 cents).
    const monthlyBasePriceWithExtra = monthlyBasePrice + extraBasePrice / 12;
    const workingPriceWithExtra = producerPrice * 100 + extraWorkingPrice;
    const workingPriceWithExtraNT = isTimeDependingConsumption ? producerPriceNT * 100 + extraWorkingPrice : 0;

    const monthlyPrice = isTimeDependingConsumption ?
        monthlyBasePriceWithExtra +
          (Number(dayConsumption) * (workingPriceWithExtra / 100) +
              Number(nightConsumption) * (workingPriceWithExtraNT / 100)) /
              12 :
        monthlyBasePriceWithExtra + (usage * (workingPriceWithExtra / 100)) / 12;

    const yearlyPrice = monthlyPrice * 12;

    return { monthlyBasePriceWithExtra, workingPriceWithExtra, monthlyPrice, yearlyPrice, workingPriceWithExtraNT };
}

export function calculateProducerPrice(workingPrice /* ct/kWh */, producer) {
    const priceInCt = Number(workingPrice) + Number(producer.deltaPrice /* ct/kWh */);
    return priceInCt / 100; /* EUR/kWh */
}

export function getYearlyWorkingPrice(workingPrice, consumption) {
    const yearlyWorkingPrice = (workingPrice * consumption) / 100;
    return roundNumber(yearlyWorkingPrice, 2);
}
