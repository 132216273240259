import { DEFAULT_ENERGY_TYPE, ENERGY_TYPES, PRODUCER_TYPES, STANDARD_PRODUCER_IDS } from '../../constants';
import { toTrimLowerCaseValue } from './converter';

/**
 * Get standard producer id by energy type
 *
 * @param {string} energyType - energy type
 *
 * @returns {string} - Producer identifier
 */
export function getStandardProducerIdByEnergyType(energyType = DEFAULT_ENERGY_TYPE) {
    const trimmedValue = toTrimLowerCaseValue(energyType);

    if (trimmedValue === ENERGY_TYPES.ELECTRICITY) {
        return STANDARD_PRODUCER_IDS.ELECTRICITY;
    }
    if (trimmedValue === ENERGY_TYPES.GAS) {
        return STANDARD_PRODUCER_IDS.GAS;
    }

    return STANDARD_PRODUCER_IDS.ELECTRICITY;
}

export function getIcon(producer) {
    const result = { icon: '' };
    const type = producer.plantType || producer.type;

    switch (type) {
        case PRODUCER_TYPES.solar: {
            result.icon = '/static/producer_types/solar.jpg';
            break;
        }
        case PRODUCER_TYPES.wind: {
            result.icon = '/static/producer_types/wind.jpg';
            break;
        }
        case PRODUCER_TYPES.biomass: {
            result.icon = '/static/producer_types/biogas.jpg';
            break;
        }
        case PRODUCER_TYPES.water: {
            result.icon = '/static/producer_types/hydro.jpg';
            break;
        }
        case PRODUCER_TYPES.default: {
            result.icon = '/static/producer_types/standard.jpg';
            break;
        }
        default: {
            result.icon = '';
        }
    }
    return result.icon;
}

export function getCurrentTariffCalculatorEnergyData({
    query: { electricityConsumption, gasConsumption, consumption, energyType = DEFAULT_ENERGY_TYPE } = {},
    availableEnergyTypes
}) {
    const isElectricityEnergyType = energyType === ENERGY_TYPES.ELECTRICITY;
    const isGasEnergyType = energyType === ENERGY_TYPES.GAS;
    // When availableEnergyTypes is [null], then we should check an available energy type by query.
    // If there is not an energyType param in the query, then we return electicity energy type, but without consumption.
    const isSingleEnergyType = availableEnergyTypes?.length === 1 && !availableEnergyTypes.includes(null);

    const actualElectricityConsumption = isElectricityEnergyType ? electricityConsumption || consumption : undefined;

    const isElectricitySelected = isSingleEnergyType ?
        availableEnergyTypes.includes(ENERGY_TYPES.ELECTRICITY) :
        !isGasEnergyType;

    const actualGasConsumption = isGasEnergyType ? gasConsumption || consumption : undefined;
    const isGasSelected = isSingleEnergyType ? availableEnergyTypes.includes(ENERGY_TYPES.GAS) : isGasEnergyType;

    return {
        electricityConsumption: actualElectricityConsumption,
        isElectricitySelected,
        gasConsumption: actualGasConsumption,
        isGasSelected
    };
}

export default { getStandardProducerIdByEnergyType, getIcon, getCurrentTariffCalculatorEnergyData };
