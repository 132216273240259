import { WIDGETS } from '../../constants';

// TODO: Cover by tests
export function bindMethods(objectWithMethods) {
    Object.keys(objectWithMethods).forEach(key => {
        if (this[key]) {
            return;
        }
        this[key] = objectWithMethods[key].bind(this);
    });
}

// TODO: Cover by tests
export function isInIframe() {
    if (!process.browser) {
        return false;
    }

    return window?.self !== window?.top || !!window?.frameElement;
}

// TODO: Cover by tests
export async function getPageInfoFromIframe() {
    return new Promise(resolve => {
        if (!process.browser || !isInIframe()) {
            return resolve(null);
        }

        return window?.parentIFrame?.getPageInfo ?
            window.parentIFrame.getPageInfo(info => resolve(info)) :
            resolve(null);
    }).catch(error => console.error(error));
}

// TODO: Cover by tests
export function delayedFunctionCall(checkCondition, callback, interval = 100, maxAttemptsNumber = 600) {
    if (!process.browser || (process.browser && window.delayedFunctionCalling)) {
        return;
    }

    let attempt = 1;
    window.delayedFunctionCalling = true;

    const timerId = setInterval(() => {
        attempt += 1;

        if (checkCondition()) {
            try {
                callback();
            } catch (error) {
                console.info('delayedFunctionCall error');
                console.error(error);
            } finally {
                window.delayedFunctionCalling = false;
                clearInterval(timerId);
            }
        }

        if (attempt >= maxAttemptsNumber) {
            console.info('delayedFunctionCall maxAttemptsNumber');
            window.delayedFunctionCalling = false;
            clearInterval(timerId);
        }
    }, interval);
}

// TODO: Cover by tests
export const performRequest = async (apiMethod, apiParams, setLoadingState = () => {}) => {
    try {
        setLoadingState(true);
        const data = await apiMethod(...apiParams);
        setLoadingState(false);
        return { data, error: null };
    } catch (error) {
        setLoadingState(false);
        console.error(error);
        return { data: null, error };
    }
};

// TODO: Cover by tests
export function isShowSupportRequestWidget(widgets) {
    const isSelectedStromeeChatWidget = Array.isArray(widgets) && widgets.includes(WIDGETS.STROMEE_FRESH_CHAT);
    const isSelectedSupportRequestWidget = Array.isArray(widgets) && widgets.includes(WIDGETS.SUPPORT_REQUEST);

    return !isSelectedStromeeChatWidget && isSelectedSupportRequestWidget;
}

// TODO: Cover by tests
export function isShowStromeeChatWidget(widgets) {
    const isSelectedStromeeChatWidget = Array.isArray(widgets) && widgets.includes(WIDGETS.STROMEE_FRESH_CHAT);

    return isSelectedStromeeChatWidget;
}
