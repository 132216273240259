import { isBlank } from './validation';

/**
 * Trim and lower case value if it string
 *
 * @param {*} value - Value
 *
 * @returns {*} Trimmed value or initial value
 */
export function toTrimLowerCaseValue(value) {
    return typeof value === 'string' ? value.trim().toLowerCase() : value;
}

export function toAddressFormat({ streetName, houseNumber, zip, cityName }) {
    return streetName && houseNumber && zip && cityName ? `${streetName} ${houseNumber}, ${zip} ${cityName}` : '';
}

export function filterBlankProps(obj) {
    return Object.keys(obj).reduce((result, propName) => {
        const propValue = obj[propName];
        if (isBlank(propValue)) {
            return result;
        }
        return { ...result, [propName]: propValue };
    }, {});
}

export function roundNumber(number, digits) {
    return Math.round((number + Number.EPSILON) * 10 ** digits) / 10 ** digits;
}
