import moment from 'moment';
import { isDevEnv, isTestEnv } from '../helpers/env';

const MEMORY_CACHE_PROP_NAME = '__stromeeMemoryCache';

class MemoryCache {
    constructor() {
        this.cache = {};
        this.itemsLimit = 350;
        this.memoryLimit = 3000;
        this.timeout = 1000 * 60 * 60 * 24;

        setInterval(() => {
            try {
                this.checkMemory();
                this.checkItemsCount();

                const keys = Object.keys(this.cache);
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    // eslint-disable-next-line no-prototype-builtins
                    if (this.cache.hasOwnProperty(key) && !isActual(this.cache[key].ttl)) {
                        this.delete(key);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }, this.timeout);
    }

    put(key, data) {
        try {
            this.checkMemory();
            this.checkItemsCount();

            this.cache[key] = { data, ttl: Date.now() };
        } catch (error) {
            console.log(error);
        }
    }

    get(key) {
        try {
            if (isDevEnv()) {
                return null;
            }

            // eslint-disable-next-line no-prototype-builtins
            const isCached = this.cache.hasOwnProperty(key) && isActual(this.cache[key].ttl);
            if (isCached && !process.browser && !isTestEnv()) {
                console.info('FROM MEMORY_CACHE', key);
            }
            return isCached ? this.cache[key].data : null;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    delete(key) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.cache.hasOwnProperty(key)) {
            delete this.cache[key];
        }
    }

    checkMemory() {
        if (process.browser) {
            return;
        }
        const used = process.memoryUsage().heapUsed / 1024 / 1024; // MB

        if (used > this.memoryLimit) {
            if (!isTestEnv()) {
                console.info(`MEMORY_CACHE: Current process use ${used}MB of memory. Server will flush cache.`);
            }
            this.cache = {}; // GC will remove all references
        }
    }

    checkItemsCount() {
        const totalKeys = Object.keys(this.cache).length;
        if (totalKeys > this.itemsLimit) {
            const trash = Object.keys(this.cache).slice(this.itemsLimit);
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < trash.length; i++) {
                this.delete(trash[i]);
            }
        }
    }

    deleteAll() {
        this.cache = {};
    }
}

if (global && !global[MEMORY_CACHE_PROP_NAME]) {
    global[MEMORY_CACHE_PROP_NAME] = new MemoryCache();
}

export default global[MEMORY_CACHE_PROP_NAME] || new MemoryCache();

function isActual(ttl) {
    // eslint-disable-next-line eqeqeq
    return moment().diff(ttl, 'days') == 0;
}
