import Axios from 'axios';
import memoryCache from '../memoryCache';
import {
    BACKEND_API,
    PRODUCER_STATUSES,
    DEFAULT_ORDER_BY,
    DEFAULT_MARKETPLACE_ID,
    DEFAULT_ZIP,
    DEFAULT_ENERGY_TYPE,
    DEFAULT_SORT_DIRECTION,
    DEFAULT_CONSUMPTION,
    DEFAULT_SIGN_UP_FLOW
} from '../../constants';
import { validateAddressParameters } from '../helpers/validation';
import { isTestEnv } from '../helpers/env';
import { calculateProducerPrice } from '../helpers/prices';
import { getIcon } from '../helpers/energy';

const handleError = error => {
    // TODO: Move to the new app too. (D)
    const headers = error?.response?.headers || {};
    const status = error?.response?.status || undefined;
    const errorDetails = error?.response?.data?.error || {};
    const errorData = { ...errorDetails, requestId: headers['x-request-id'], status };

    console.error(errorData, headers);

    return errorData;
};

// TODO: Most of the errors do not have handler on the page.
//  Need add handlers for this in the current app and in the new app.

export function getProducers(
    { workingPrice = 0, workingPriceNT },
    {
        orderBy = DEFAULT_ORDER_BY,
        zip = DEFAULT_ZIP,
        ascDesc = DEFAULT_SORT_DIRECTION,
        isEcoenergy,
        marketplaceId = DEFAULT_MARKETPLACE_ID
    } = {}
) {
    const url = `${BACKEND_API}/producers/getAll`;
    const requestData = { params: { orderBy, zip, ascDesc, marketplaceId } };

    // const cacheKey = `${url}-${JSON.stringify(requestData)}-${workingPrice}-${isEcoenergy}`;
    // const cacheData = memoryCache.get(cacheKey);
    // if (cacheData) {
    //     return cacheData;
    // }

    const result = { producers: [] };
    return Axios.get(url, requestData)
        .then(response => {
            if (response.data && response.data.length) {
                result.producers = response.data
                    .filter(producer => isEcoenergy || producer.status !== PRODUCER_STATUSES.standard)
                    .map(producer => ({
                        id: `${producer.id}`,
                        name: producer.name,
                        capacity: producer.capacity,
                        type: producer.plantType,
                        description: producer.description,
                        postcode: producer.postcode,
                        city: producer.city,
                        street: producer.street,
                        lat: producer.lat,
                        lon: producer.lon,
                        address: `${producer.postcode} ${producer.city}`,
                        picture: producer.picture || getIcon(producer),
                        backgroundImage: producer.backgroundImage,
                        price: Number(calculateProducerPrice(workingPrice, producer)) /* EUR/kWh */,
                        priceNt: workingPriceNT ?
                            Number(calculateProducerPrice(workingPriceNT, producer)) :
                            0 /* EUR/kWh */,
                        deltaPrice: Number(producer.deltaPrice) /* ct/kWh */,
                        status: producer.status,
                        volumePerYear: producer.volumePerYear,
                        commissioning: producer.commissioning,
                        extendedDescription: producer.extendedDescription
                    }));
                // memoryCache.put(cacheKey, result.producers);
            }

            return result.producers;
        })
        .catch(error => {
            throw handleError(error);
        });
}

export function getProducer(id, workingPrice = 0, workingPriceNT) {
    const url = `${BACKEND_API}/producers/get/${id}`;

    // const cacheKey = `${url}-${workingPrice}-${workingPriceNT}`;
    // const cacheData = memoryCache.get(cacheKey);
    // if (cacheData) {
    //     return cacheData;
    // }

    const result = { producer: {} };
    return Axios.get(url)
        .then(response => {
            if (response && response.data) {
                const producer = response.data;
                result.producer = {
                    id: `${producer.id}`,
                    capacity: producer.capacity,
                    name: producer.name,
                    type: producer.plantType,
                    description: producer.description,
                    postcode: producer.postcode,
                    city: producer.city,
                    street: producer.street,
                    lat: producer.lat,
                    lon: producer.lon,
                    address: `${producer.postcode} ${producer.city}`,
                    picture: producer.picture || getIcon(producer),
                    backgroundImage: producer.backgroundImage,
                    price: Number(calculateProducerPrice(workingPrice, producer)) /* EUR/kWh */,
                    priceNT: workingPriceNT ?
                        Number(calculateProducerPrice(workingPriceNT, producer)) :
                        0 /* EUR/kWh */,
                    deltaPrice: Number(producer.deltaPrice) /* ct/kWh */,
                    status: producer.status,
                    volumePerYear: producer.volumePerYear,
                    commissioning: producer.commissioning,
                    extendedDescription: producer.extendedDescription
                };
                // memoryCache.put(cacheKey, result.producer);
            }

            return result.producer;
        })
        .catch(error => {
            throw handleError(error);
        });
}

// TODO: Use an object as argument with the params for this function @Anton
export function compareProduct(
    zip = DEFAULT_ZIP,
    consumption = DEFAULT_CONSUMPTION,
    cityName,
    signupFlow = DEFAULT_SIGN_UP_FLOW,
    tariff = {},
    streetName,
    houseNumber,
    isZipBasedPricing = false,
    costPlus,
    energySubTypeId,
    gridId,
    dayConsumption,
    nightConsumption
) {
    if (!isTestEnv()) {
        console.info(
            'compareProduct call',
            JSON.stringify({
                zip,
                consumption,
                cityName,
                signupFlow,
                tariff,
                streetName,
                houseNumber,
                isZipBasedPricing,
                costPlus,
                energySubTypeId,
                gridId,
                dayConsumption,
                nightConsumption
            })
        );
    }


    const validationError = validateAddressParameters(zip, streetName, houseNumber);

    if (validationError) {
        throw new Error(validationError);
    }

    // eslint-disable-next-line
    const { pc_campaign_identifer, pc_product_id, pc_price_fixation, pricing } = tariff;

    const correctValueForBE = v => (!v ? undefined : v);

    const timeDependingConsumption = {
        HT: Number(dayConsumption),
        NT: Number(nightConsumption)
    };

    const isTimeDependingConsumption = timeDependingConsumption.HT && timeDependingConsumption.NT;
    const usage = isTimeDependingConsumption ? timeDependingConsumption : consumption;

    const requestData = {
        postcode: correctValueForBE(zip),
        usage,
        business: false, // FIXME: Do we need this? @Eugene
        cityName: correctValueForBE(cityName),
        signupFlow: correctValueForBE(signupFlow),
        tariffId: tariff.id, // FIXME: All this params from tariff like pc_product_id and so on we should get by tariff id on BE @Eugene
        pc_campaign_identifer,
        pc_product_id,
        pc_price_fixation,
        pricing,
        energyType: tariff.energyType || DEFAULT_ENERGY_TYPE,
        isZipBasedPricing,
        streetName: correctValueForBE(streetName),
        houseNumber: correctValueForBE(houseNumber),
        costPlus,
        energySubTypeId: correctValueForBE(energySubTypeId),
        gridId: correctValueForBE(gridId),
        preciseOutput: true
    };

    const url = `${BACKEND_API}/dictionaries/products/compare`;

    // const cacheKey = `${url}-${JSON.stringify(requestData)}`;
    // const cacheData = memoryCache.get(cacheKey);
    // if (cacheData) {
    //     return Promise.resolve(cacheData);
    // }

    console.time('Compare Product Call');
    return Axios.post(url, requestData)
        .then(
            response => {
                // memoryCache.put(cacheKey, response.data);
                console.timeEnd('Compare Product Call');
                return response.data;
            }
        )
        .catch(error => {
            console.timeEnd('Compare Product Call');
            console.timeEnd('Error !!!');
            throw handleError(error);
        });
}

export function createOrder(userData, lang = 'de') {
    const url = `${BACKEND_API}/contract/create?lang=${lang}`;

    if (!isTestEnv()) {
        console.info(url, userData);
    }
    return Axios.post(url, userData)
        .then(response => response.data)
        .catch(error => {
            throw handleError(error);
        });
}

export function getProviders(energyType = DEFAULT_ENERGY_TYPE) {
    const url = `${BACKEND_API}/dictionaries/all-providers?energyType=${energyType}`;

    const cacheData = memoryCache.get(url);
    if (cacheData) {
        return cacheData;
    }

    return Axios.get(url)
        .then(response => {
            const data = response?.data?.data;

            memoryCache.put(url, data);

            return data || [];
        })
        .catch(error => {
            throw handleError(error);
        });
}

export function getCities(postcode = DEFAULT_ZIP) {
    const url = `${BACKEND_API}/dictionaries/cities`;
    const requestData = { params: { postcode } };

    const cacheKey = `${url}-${JSON.stringify(requestData)}`;
    const cacheData = memoryCache.get(cacheKey);
    if (cacheData) {
        return cacheData;
    }

    return Axios.get(url, requestData)
        .then(({ data: { data } = {} }) => {
            memoryCache.put(cacheKey, data);
            return data;
        })
        .catch(error => {
            throw handleError(error);
        });
}

export function getLandingDetails(pageName) {
    const url = `${BACKEND_API}/landings/${pageName}`;

    const cache = memoryCache.get(url);
    if (cache) {
        return cache;
    }

    return Axios.get(url)
        .then(({ data: { data } = {} }) => {
            memoryCache.put(url, data);
            return data;
        })
        .catch(error => {
            throw handleError(error);
        });
}

export function getLandingTariffs(pageName, energySubTypeId) {
    const energySubTypeIdParam = energySubTypeId ? `?energySubTypeId=${energySubTypeId}` : '';
    const url = `${BACKEND_API}/landings/${pageName}/tariffs${energySubTypeIdParam}`;

    // const cache = memoryCache.get(url);
    // if (cache) {
    //     return cache;
    // }

    return Axios.get(url)
        .then(
            ({ data: { data } = {} }) =>
                // memoryCache.put(url, data);
                data
        )
        .catch(error => {
            throw handleError(error);
        });
}

export function getTariffById(tariffId, signupFlow) {
    const url = `${BACKEND_API}/dictionaries/tariffs/${tariffId}?signupFlow=${signupFlow}`;

    // const cache = memoryCache.get(url);
    // if (cache) {
    //     return cache;
    // }

    return Axios.get(url)
        .then(result => {
            const data = result?.data;
            // memoryCache.put(url, data);
            return data;
        })
        .catch(error => {
            throw handleError(error);
        });
}

export function getClosestProducer(city) {
    const url = `${BACKEND_API}/dictionaries/getClosestProducer/${city}`;

    const cache = memoryCache.get(url);
    if (cache) {
        return cache;
    }

    return Axios.get(url)
        .then(({ data: { producer } = {} } = {}) => {
            memoryCache.put(url, producer);
            return producer;
        })
        .catch(error => {
            throw handleError(error);
        });
}

export function checkEmail(email) {
    return Axios.get(`${BACKEND_API}/check/email/${email}`, { headers: { 'Cache-Control': 'no-cache' } })
        .then(response => ({
            isDuplicateEmail: response && response.data && response.data.data && response.data.data.isExist
        }))
        .catch(error => {
            throw handleError(error);
        });
}

export function login(credentials) {
    return Axios.post(`${BACKEND_API}/account/login`, credentials)
        .then(response => (response && response.data ? response.data : {}))
        .catch(error => {
            throw handleError(error);
        });
}

// TODO: Looks like should be removed @Anton
export function getSurvey(surveyId) {
    if (!surveyId) {
        return {};
    }

    const url = `${BACKEND_API}/survey/${surveyId}`;

    const cache = memoryCache.get(url);
    if (cache) {
        return cache;
    }

    return Axios.get(url)
        .then(response => {
            const data = response && response.data ? response.data : null;
            memoryCache.put(url, data);
            return data;
        })
        .catch(error => {
            console.error(handleError(error));
            // TODO: Need think about this solution, for current moment it is hotfix, in common the survey functionality don't need in the new app and should be remove from BE in future @Euegne.U/Eugene.M
            return {};
        });
}

// TODO: Looks like should be removed @Anton
export function saveSurveyResult(survey) {
    return Axios.post(`${BACKEND_API}/survey/${survey.id}/response`, survey)
        .then(response => (response && response.data ? response.data : null))
        .catch(error => {
            throw handleError(error);
        });
}

// TODO: Looks like should be removed
export function getBonusProgramOptions(pageName) {
    const url = `${BACKEND_API}/landings/bonusProgram/${pageName}`;

    const cache = memoryCache.get(url);
    if (cache) {
        return cache;
    }

    return Axios.get(url)
        .then(({ data: { data = [] } = {} } = {}) => {
            memoryCache.put(url, data);
            return data;
        })
        .catch(error => {
            throw handleError(error);
        });
}

export function getFaqSections() {
    const url = `${BACKEND_API}/faqSections`;

    const cache = memoryCache.get(url);
    if (cache) {
        return cache;
    }

    return Axios.get(url)
        .then(response => {
            const data = response && response.data ? response.data : [];
            memoryCache.put(url, data);
            return data;
        })
        .catch(error => {
            throw handleError(error);
        });
}

export function getFaqTopQuestions() {
    const url = `${BACKEND_API}/faqSections/topQuestions`;

    const cache = memoryCache.get(url);
    if (cache) {
        return cache;
    }

    return Axios.get(url)
        .then(response => {
            const data = response && response.data ? response.data : [];
            memoryCache.put(url, data);
            return data;
        })
        .catch(error => {
            throw handleError(error);
        });
}

export function sendLeadDataToBE(data) {
    return Axios.post(`${BACKEND_API}/leads`, data)
        .then(response => response.data)
        .catch(error => {
            throw handleError(error);
        });
}

export function sendSignUpFormsDataToBe(data) {
    const url = `${BACKEND_API}/system/validate/registrationForm`;

    if (!isTestEnv()) {
        console.info(url, data);
    }
    return Axios.post(url, data)
        .then(result => result?.data)
        .catch(error => console.error(handleError(error)));
}

export function getReferralCodeByEmail(userEmail) {
    return Axios.get(`${BACKEND_API}/referral/code?email=${encodeURIComponent(userEmail)}`)
        .then(response => response.data)
        .catch(error => {
            throw handleError(error);
        });
}
